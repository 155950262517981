import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from '@components/Button'
import './DescriptionCard.css'
import { navigate } from 'gatsby'

function DescriptionCard({ noCrown, noButton, longDescription, titleStyle, title, description }) {
  return (
    <div className="card-container">
      {
        !noCrown &&
        <div className="horizontal-line" />
      }
      <h1 className="title" style={titleStyle}> {title} </h1>
      <p className={classnames('description', { 'description-long': longDescription })}>{description}</p>
      {
        !noButton &&
        <Button className="action-btn" type={'secondary'} size={'large'} onClick={() => navigate('/massachusetts')}> Massachusetts → </Button>
      }&nbsp;
      {
        !noButton &&
        <Button className="action-btn" type={'secondary'} size={'large'} onClick={() => navigate('/musicalshowcases')}> Musical Showcases → </Button>
      }&nbsp;
      {
        !noButton &&
        <Button className="action-btn" type={'secondary'} size={'large'} onClick={() => navigate('/poems')}> Poems → </Button>
      }
    </div>
  )
}

DescriptionCard.propTypes = {
  noCrown: PropTypes.bool,
  longDescription: PropTypes.bool,
  noButton: PropTypes.bool,
  titleStyle: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string
}


DescriptionCard.defaultProps = {
  title: 'About Me',
  description: <div>
    <p>Born and raised in Manhattan, Sara Curry, a working nurse, took it to another level over 25 years ago.  She began studying voice evenings at the Greenwich House School of Music for four years, then went on to an advanced course at the New School, and then came under the tutelage of  Actor George Axiltree from H.B. Studios for seven years..  Classes at HB are taught by actors who sing, and one has the opportunity to learn to tell the story and connect with the audience.  For Sara, it opened  up a whole new world of cabaret ideas and friends.</p>

    <p>Sara studied different genres of music to include Stage and Screen, Jazz, American Popular Song, Classic Latin favorites, and unforgettable standards.  Sara loves stylizing an old standard and giving it a completely new feel rhythmically.  At HB Studio she was able to explore acting objectives with other classmates, and bring live drama to a set-up performance area.  These are her most memorable days in the development of her art form.</p>

    <p>Sara took her skills to her favorite vacation spot Eddy Farm Resort Hotel near the Delaware River, where vacationers enjoy golf, tennis, dancing, and song.  She connected with Walt Hasen pianist and Roger Acker's Band  and was featured as a singer at poolside and nightly at their dances in the pavilion throughout the nineties. With her accompanist Walt Hasen, Sara sang at many events and parties in the New Jersey area.</p>

    <p>Sara sang in the choir throughout her life starting with elementary school.  Although her career in nursing was very important to her, singing has always been a pivotal part of her life, and she nurtured her three children about the importance of having music in their lives.</p>

    <p>Sara also took a two-year home study course in writing and earned her diploma.  It honed her craft for writing which she loved from a young age, and she began writing a myriad of poems which are posted to her website.</p>

    <p>There are places in Manhattan that Sara will never forget that played an important role in her career and recreational moments.  A graduate from St. Patrick's Cathedral, she landed a job years later in her nursing career that was right across from Rockefeller Center as a rehabilitation nurse for a large company. During off hours... from Serendipity to Central Park,  to Cabaret Shows, to Broadway....it' was all a delight!</p>
  </div>

}

export default DescriptionCard