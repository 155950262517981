import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import DescriptionCard from '@components/DescriptionCard'
import Image from '@components/Image'
import './HomePage.css'
import { Grid } from '@components/Grid'
import ReactAudioPlayer from 'react-audio-player'
import mp3 from './pure-imagination.mp3'

function HomePageContainer() {
  const data = useStaticQuery(graphql`
  query {
    allFile(filter: { name: { eq: "image-home-page" }, sourceInstanceName: { eq: "images"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 780) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    gridImages: allFile(filter: { name: { regex: "/home-grid-*/" }, extension: { regex: "/(jpeg|jpg|gif|png)/" }, sourceInstanceName: { eq: "images"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  `)

  return (
    <div>
      <div className="home-container">
        <div className="item1">
          <DescriptionCard />
        </div>
        <div className="item2">
          <Image withFrame fluid={data.allFile.edges[0].node.childImageSharp.fluid} />
        </div>
      </div>
      <ReactAudioPlayer
        src={mp3}
        controls
      />
      <div className="image-grid">
        <Grid items={data.gridImages.edges} />
      </div>
    </div>
  )
}

export default HomePageContainer